import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import {questionConfig} from 'constants/questions';
import css from '../Question.scss';

const MultiSelect = ({
    id,
    name,
    helperText,
    required,
    items,
    defaultValues = [],
    customValidationError,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    // Get question UI configuration.
    const {error} = questionConfig[id] || {};
    // Create a hash of all the default values set to true
    const defaultCheckedHash = defaultValues.reduce(
        (hash, defaultValue) => ({...hash, [defaultValue]: true}),
        {}
    );
    const [checkedHash, setCheckedHash] = useState(defaultCheckedHash);

    const handleOnChange = (value, checked) => {
        const newCheckedHash = {
            ...checkedHash,
            [value]: checked,
        };

        setCheckedHash(newCheckedHash);

        if (onChange) {
            const selectedValues = Object.keys(newCheckedHash).reduce(
                (arr, key) => {
                    return newCheckedHash[key] ? arr.concat(key) : arr;
                },
                []
            );

            onChange(selectedValues);
        }
    };

    return (
        <div>
            {helperText && <div className={css.divMargin}>{t(helperText)}</div>}
            {customValidationError}
            {items.map(({value, label}) => {
                return (
                    <Checkbox
                        key={value}
                        id={`${id}_${value}`}
                        name={name}
                        value={value}
                        i18nItemLabel={t(label)}
                        label={<Label>{label}</Label>}
                        title={label}
                        error={<FormElementError>{t(error)}</FormElementError>}
                        defaultChecked={checkedHash[value]}
                        onChange={evt => {
                            handleOnChange(value, evt.target.checked);
                        }}
                    />
                );
            })}
        </div>
    );
};

MultiSelect.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    required: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    defaultValues: PropTypes.arrayOf(PropTypes.string),
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {String[]} selectedValues - Array of selected values.
     */
    onChange: PropTypes.func,
};

export default MultiSelect;
