import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {questionConfig, questionFieldTypes} from 'constants/questions';
import {questionUiType} from 'constants/type';
import {camelToUnderscoreCase} from 'utilities/utils';
import BaseQuestion from './BaseQuestion';

const {TEXT_INPUT, RADIO, BIRTH_DATE} = questionUiType;

const {
    TEXT_FIELD,
    SINGLE_SELECT_ENUM,
    BOOLEAN_FIELD,
    INTEGER_FIELD,
    DATE_FIELD,
    MULTI_SELECT_ENUM,
} = questionFieldTypes;

const DATETIME = 'DATETIME';
const TEXT = 'TEXT';
const BOOLEAN = 'BOOLEAN';
const INT = 'INT';

const UpsellQuestion = ({
    id,
    necessity,
    answerType,
    questionGroup,
    possibleAnswers,
    onChange,
    errorMessage,
}) => {
    const {t, i18n} = useTranslation('questions', {useSuspense: false});
    const questionName = camelToUnderscoreCase(id).toUpperCase();
    const name = id;
    const required = necessity === 'REQUIRED';

    if (!questionConfig[questionName]) {
        // debugger;
        // eslint-disable-next-line no-console
        console.warn(`Missing config in constant/questions: ${questionName}`);
    }

    // Get uiType from configuration if exists, it maybe be undefined
    let uiType = questionConfig[questionName]?.uiType;

    if (!uiType) {
        if (answerType === TEXT) {
            uiType = TEXT_INPUT;
        } else if (answerType === BOOLEAN) {
            uiType = RADIO;
        } else if (answerType === DATETIME) {
            uiType = BIRTH_DATE;
        } else if (answerType.indexOf('Enum.') === 0) {
            uiType = RADIO;
        } else {
            uiType = TEXT_INPUT;
        }
    }

    let dataType;

    if (answerType === TEXT) {
        dataType = TEXT_FIELD;
    } else if (answerType === BOOLEAN) {
        dataType = BOOLEAN_FIELD;
    } else if (answerType === DATETIME) {
        dataType = DATE_FIELD;
    } else if (answerType.indexOf('Enum.') === 0) {
        // both single select enum and multi select enum will start with 'Enum.' in answerType
        // need to update dataType base on questionName here
        if (questionName === 'COMORBIDITY') {
            dataType = MULTI_SELECT_ENUM;
        } else {
            dataType = SINGLE_SELECT_ENUM;
        }
    } else if (answerType === INT) {
        dataType = INTEGER_FIELD;
    } else {
        dataType = TEXT_FIELD;
    }

    // Add T
    const defaultOptions = questionConfig[questionName]?.options;
    let options = [];

    if (Array.isArray(defaultOptions)) {
        options = defaultOptions.map(({value, label}) => ({
            value,
            label: t ? t(label) : label,
        }));
    } else if (dataType === BOOLEAN_FIELD) {
        options = [
            {value: 'true', label: t('common:options.Yes')},
            {value: 'false', label: t('common:options.No')},
        ];
    } else {
        options = possibleAnswers.map((answer, index) => {
            let label = answer.description;

            if (
                i18n.exists(`questions:${questionName}.options.${answer.value}`)
            ) {
                label = t(`${questionName}.options.${answer.value}`);
            } else if (i18n.exists(`common:options.${answer.value}`)) {
                label = t(`common:options.${answer.value}`);
            } else {
                // TODO: alert to missing translation
                // doing the translation to debug
                t(`${questionName}.options.${answer.value}`);
            }

            return {
                value:
                    questionName === 'INSULIN_USAGE'
                        ? String(index + 1)
                        : answer.value,
                label,
            };
        });
    }

    const handleChange = value => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <BaseQuestion
            questionName={questionName}
            name={name}
            required={required}
            uiType={uiType}
            dataType={dataType}
            options={options}
            onChange={handleChange}
            errorMessage={errorMessage}
        />
    );
};

UpsellQuestion.propTypes = {
    id: PropTypes.string.isRequired,
    necessity: PropTypes.oneOf(['REQUIRED', 'OPTIONAL', 'SKIP']).isRequired,
    answerType: PropTypes.string.isRequired,
    questionGroup: PropTypes.string,
    possibleAnswers: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        })
    ),
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
};

UpsellQuestion.defaultProps = {};

export default UpsellQuestion;
